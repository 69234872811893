/**
 *  @name: 新版merchant-商户模块接口
 *  @version: 3.0
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/merchant";

/**
 *商户信息
 */
// 【user022】-获取当前用户所属商户列表-云玉峰
export const findCurrentUserRelationMerchantList = () => {
  return http.get(`${prefix}/find/current/user/relation/merchant/list`);
};

// 【merchant132】获取业态下商户列表接口
export function windowUserManageMerchantList(data) {
  return http.get(`${prefix}/window/user/manage/merchant/list`, data);
}
// 查询子景区列表-刘院民
export function subScenics(data) {
  return http.get(`${prefix}/merchant/of/sub/scenic/tree`, data);
}
// 通过商户id查询对应商户详情-家伟
export function merchantSettingInfo(data) {
  return http.get(`${prefix}/merchant/setting/info`, data);
}

// 通过商户获取 分时预约 + 订单信息采集(不需要传入商户id) http://192.168.1.50:40001/project/41/interface/api/3881
export function merchantSettingOrderInfo(data) {
  return http.get(`${prefix}/merchant/setting/order/info`, data);
}
// 根据业态获取用户管理商户列表 http://192.168.1.50:40001/project/41/interface/api/4029
export function getMerchantListType(data) {
  return http.get(`${prefix}/merchant/list/of/merchant/type`, data);
}
