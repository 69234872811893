import CryptoJS from "crypto-js";

/**
 *@name: AES加密
 *@date: 2021-03-17
 *@author: 易远胜
 *@params: {
 *   word: String 待加密的字符串,
 *}
 *@return: {
 *   String 对密码加密的秘钥-十六进制的随机字符串(位数是8的倍数),
 *   String 加密的密文
 *}
 */
export function encrypt(word = "") {
  // 生成一个随机的十六进制的字符串
  const str = "abcdef0123456789";
  let key = ""; // 秘钥
  let _key;
  let password;
  let encrypted;
  let encryptedWord;
  for (let i = 0; i < 16; i++) {
    key += str[parseInt(Math.random() * 16)];
  }
  _key = CryptoJS.enc.Utf8.parse(key);
  password = CryptoJS.enc.Utf8.parse(word);
  encrypted = CryptoJS.AES.encrypt(password, _key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  encryptedWord = encrypted.toString();
  return { key, encryptedWord };
}

/**
 *@name: AES解密
 *@date: 2021-03-17
 *@author: 易远胜
 *@params: {
 *   key: String 对密码加密的秘钥-十六进制的字符串
 *   word: String 需要解密的密文,
 *}
 *@return: String 解密的明文
 */
export function decrypt(key = "", word = "") {
  const _key = CryptoJS.enc.Utf8.parse(key);
  const decrypt = CryptoJS.AES.decrypt(word, _key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt);
}
